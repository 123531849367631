@media (hover: hover) {
  .button:hover,
  .button:active {
    -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    transform: scale(0.95);
  }
  .button.shadow:hover {
    box-shadow: hsl(33, 7%, 76%) 0px 20px 30px -10px;
  }
  .icons:hover {
    text-decoration: underline;
    filter: brightness(1.5);
    cursor: pointer;
  }
  .button.shadow.black:hover {
    box-shadow: hsl(0, 0%, 27%) 0px 5px 30px -10px;
  }
  .swiper-wrapper:hover {
    cursor: drag;
  }
  .faqAC .ac:hover::after {
    -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    opacity: 1;
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    filter: brightness(1);
    background-color: var(--lightGreen);
    -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .content.paddedContent.noTop {
    padding-top: 0vh;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .ubytovani.evenCol {
    flex-direction: column;
  }
  .ubytovani.ubytovani.evenCol p {
    max-width: 100%;
    margin-left: 0%;
  }
  .faqAC {
    max-width: 100%;
    margin-left: 0%;
    counter-reset: section;
  }
  .ubytovani.evenCol.par > div > p {
    max-width: 100%;
    margin-left: 0%;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1600px) {
  :root {
    --item: calc(100vw / 3.1);
    --item: calc(80vh * 0.7);
  }
  .restaurace .inner {
    max-height: calc(100vw * 0.7 - 3rem);
  }
  .bigSwipe .swiper-slide {
    width: calc(50vw - 3.5rem);
    height: calc(50vw * 1.3);

    flex: 1;
  }
  .bigSwipe .swiper-slide .swiperContent {
    width: calc(50vw - 3.5rem);
    height: calc(50vw * 1.3);
  }
  .columns2 {
    column-count: 2;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 3500px) {
  :root {
    --item: calc(100vw / 4);
    --item: calc(90vh * 0.6);
  }

  .bigSwipe .swiper-slide .swiperContent {
    width: calc(1600px / 2 - 3.5rem);
    height: calc(800px * 1.3);
  }
  .bigSwipe .swiper-slide {
    flex: 1;
  }
}

@media only screen and (max-width: 800px) {
  :root {
    font-size: 10px;

    --radius: 2.5rem;
    --padding: 5rem;
    --item: 90vw;
    --item: calc(90vw + 0.5rem);
  }
  .button {
    /* transform: scale(0.8); */
  }
  .smallSwipe r.swiper-slide:nth-last-of-type(1) .swiperContent {
    padding-right: 2rem;
  }
  .smallSwipe .swiperContent {
    padding: 0 1.5rem 0 1.5rem;
    padding-left: 2rem;
    padding-right: 0rem;
    padding: 0 !important;
  }

  .content.paddedContent {
    padding-left: 0;
    padding-right: 0;
  }

  .content:not(.paddedContent) {
    padding: 0rem;
  }
  .content.kontakt {
    padding-bottom: 2rem;
  }

  .inner .content {
    padding: 0 2rem;
  }

  .logo {
    width: 45%;
  }
  .about {
    margin-top: 3rem;
    padding: 10rem 2rem 2rem;
  }
  .h1 {
    padding-top: 8rem;

    padding-bottom: 0rem;
    letter-spacing: -2px;
    font-size: clamp(5.5rem, 0.0741rem + 14.0741vw, 24rem);
    padding-left: 2rem;
  }
  .lesText {
    max-width: 100%;
  }

  .swiper-slide {
    height: calc(var(--item) * 1.3);
  }
  .swiper-slide .inner {
    height: calc(var(--item) * 1.3);
  }

  .restaurace .inner {
    flex-direction: column-reverse;
    height: unset;
  }

  .restaurace .inner div.text {
    padding: 3rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .ubytovani {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .lineDividerWrap {
    padding: 0 3rem 0 3rem;
  }
  .restaurace .inner div.text a {
    margin-left: unset;
  }

  .quote {
    width: 95%;
  }
  .les {
    min-height: 100svh;
  }
  .les .inner {
    justify-content: space-evenly;
  }
  .switchItem .button {
    left: 2rem;
    bottom: 2rem;
  }
  .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal,
  .swiper-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
  .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
    width: calc(100vw - 6rem);
    max-width: calc(1600px - 6rem);
    border-radius: 50vw;
    overflow: hidden;
    height: 2px;
  }
  .footerBottom {
    padding: 2rem;
  }
  .footerBottom .top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footerBottom .perex {
    text-align: center;
    padding-top: 0;
  }

  .phone2 {
    display: none;
  }

  .footerBottom .top ul {
    display: none;
  }

  .bigSwipe .swiper-slide {
    width: calc(80vw);
    height: calc(80vw * 1.3);
  }
  .bigSwipe .swiper-slide .swiperContent {
    width: calc(80vw);
    height: calc(80vw * 1.3);
  }
  .topSwipe {
    width: 100vw;
    padding-left: 0rem;
    padding-right: 0rem;
    max-width: 100vw;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
  }
  .bigSwipe .swiper-wrapper {
    width: 100%;
  }
  .restaurace .inner div.img {
    height: 100vw;
    width: 100vw;
    overflow: hidden;
  }
  .restaurace .inner {
    max-height: unset;
  }

  .bigSwipe .swiper-wrapper {
    column-gap: 0rem;
  }
  .bigSwipe .swiper-slide {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .bigSwipe .swiper-slide:nth-of-type(1) {
    margin-left: 2rem;
    margin-right: 1rem;
  }
  .bigSwipe .swiper-slide:nth-of-type(2) {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bigSwipe .swiper-slide .button {
    transform-origin: 0 100%;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
  }
  .topSwipe {
    padding: 3rem 0;
  }
  .ubytovani {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .ubytovani a.button {
    margin-top: 3rem;
    margin-right: auto;
    transform-origin: left;
  }

  .footer a.button {
    transform-origin: left;
  }

  .restaurace a.button {
    margin-top: 3rem;
    margin-right: auto;
    transform-origin: left;
  }

  .introContent {
    min-height: unset;
    height: 100vh;
  }
  .introContent.page {
    min-height: unset;
    height: auto;
    min-height: var(--a);
  }
  .kontakt .introContent {
    min-height: var(--app-height);
  }
  .kontakt .introContent.page {
    height: auto;
  }
  .introContent.page .help {
    margin-bottom: 0;
  }

  .introContent.page .ubytovaniSwiper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .ubytovani.ubytovani.evenCol p {
    max-width: 100%;
    margin-left: 0%;
  }
  .faqAC {
    max-width: 100%;
    margin-left: 0%;
    counter-reset: section;
  }
  .bookFormInner,
  .iframeWrap {
    padding: 1rem;
  }

  h1.page br {
    display: none;
  }
  .imageSection.rest .inner2,
  .imageSection.penz .inner2 {
    padding: 2rem;
  }
  .ubytovani.evenCol.par > div > p {
    max-width: 100%;
    margin-left: 0%;
  }
  .listek p {
    max-width: 100%;
    margin-left: 0%;
    padding-left: 0;
  }
  .menuHolder {
    padding: 0rem;
  }
  .contentFull {
    padding: 0rem;
  }
  .restaurace.mapa .inner {
    width: 100vw;
    height: 100vw;
    min-height: 100vw;
    /* border-bottom-left-radius: 0;
		border-bottom-right-radius: 0; */
  }
  .restaurace.mapa {
    min-height: unset;
    /* border-bottom-left-radius: 0;
		border-bottom-right-radius: 0; */
    background-image: none;
  }
  .restaurace.mapa .inner div.img {
    /* border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;*/
    min-height: unset;
  }

  .content.kontakt .ubytovani.evenCol.par {
    align-items: center;
    gap: 0;
  }
  .columns2 {
    column-count: 1;
    column-gap: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
  }
  .menuHolder .wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
  }
  .menuHolder .ubytovani.evenCol.par {
    gap: 0;
  }
  p.noWrap {
    padding-top: 0rem;
  }
  p {
    font-size: 1.4rem;
    font-family: var(--regulartext);
    line-height: 1.85;
  }
  .singleSwipe .swiper-slide:nth-of-type(1) .swiperContent {
    padding: 0rem;
  }
  .introContent.page .ubytovaniSwiper:has(> .singleSwipe) {
    padding-bottom: 0;
  }

  .about.onPage {
    margin-top: calc(var(--radius) * -2);
  }
  .about.onPage.secondAbout {
    margin-top: calc(var(--radius) * -2);
    padding-top: calc(var(--radius) * 5);
  }
  .h1.page {
    padding: 0 2rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 4.5rem;
    width: 4.5rem;
    background-size: 2.5rem;
  }
  .swiper-pagination-progressbar {
    margin-bottom: 2rem;
  }
  .swiper-button-prev {
    margin-left: 1rem;
  }
  .swiper-button-next {
    margin-right: 1rem;
  }
  .swiper-button-Holder {
    justify-content: center;
    margin-top: 2rem;
    height: 8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  .noPadMob {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
  }
  .footerBottom .bottom {
    flex-direction: ;
  }
  .footerBottom .bottom div:first-of-type {
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
  }
  .footerBottom .bottom div:last-of-type {
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .footerBottom .bottom .icons {
    font-size: 1.4rem;
  }
  .footerBottom .bottom div:last-of-type .icons:first-of-type {
    padding-left: 0;
  }
  .footerBottom .bottom div:first-of-type .icons.left {
    padding-right: 0;
    padding-top: 0rem;
  }
  .footerBottom .bottom div:last-of-type .icons {
    padding-right: 0;
    padding-top: 0rem;
    padding-left: 0;
  }
  .fullBorder.noBottomBorder {
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }
  .noBottomBorder {
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }
  .non-sticky,
  .sticky {
    padding: 1.5rem;
  }
  .sticky {
    padding-top: 2.3rem;
  }
  .button > div:after {
    width: 40px;
    height: 40px;
  }
  .button ul {
    padding: 9px 1.6rem;
    line-height: 20px;
  }
  .button > div {
    width: 40px;
    height: 40px;
  }
  .ubytovaniSwiper:has(.fadeSlide) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .swiper.fadeSlide {
    height: 140vw;

    overflow: hidden;
  }
  .swiper.fadeSlide .swiperContent {
    height: 140vw;
  }
  .swiper.fadeSlide .swiper-slide .inner {
    height: 140vw;
  }
  .swiperHolder:has(.fadeSlideActivity),
  .swiper.fadeSlideActivity,
  .swiper.fadeSlideActivity .swiperContent,
  .swiper.fadeSlideActivity .swiper-slide .inner {
    height: calc(100vw * 0.7);
  }
  .pageStyle .about {
    padding-top: 7rem;
  }
  .swiperHolder:has(.fadeSlide) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pageStyle .quote {
    max-width: 100%;
  }
  .columns2Flex {
    column-count: 3;
    flex: 1;
    column-gap: 10rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    gap: 5rem;
  }
  .columns2Flex h2 {
    padding-bottom: 0;
  }
  .swiper.fadeSlideActivity {
    border-radius: var(--radius);
    padding: 0rem;
  }

  .swiperHolder:has(.swiper.fadeSlideActivity) {
    background-color: var(--green);
  }
  .swiperHolder:has(.swiper.fadeSlideActivity.activity02) {
    background-color: var(--lightGreen);
  }
  .columns2Flex > div {
    flex: 1;
  }
  .swiper.fadeSlideActivity .swiper-button-Holder {
    transform: translateY(-10rem);
    justify-content: flex-end;
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 300px) and (max-height: 500px) {
  .help {
    margin-bottom: unset;
  }
  .introContent {
    min-height: 100vh;
    height: unset;
  }
}
