@import url("morion/morion.css");
@import url("jakarta/jakarta.css");
@import url("simple-lightbox.css");

@import url("swiper.css");
@import url("reset.css");
@import url("root.css");
@import url("style.css");
@import url("query.css");

.menuItems :is(*) {
  pointer-events: all;
}
