*,
*::after,
*::before {
  box-sizing: border-box;
  font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
}
a {
  color: inherit !important;
}
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
}
.itemContent {
  display: flex;
  flex-direction: column;
}
[data-phone]:hover {
  cursor: pointer;
  text-decoration: underline;
}
.itemContent .text {
  padding: 2rem;
}
.makephotoSwiper {
  display: flex;
  align-items: center;
}
.makephotoSwiper .swiper-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.makephotoSwiper .swiper-wrapper .swiper-slide {
  scale: 0.8;
  /* flex-basis: 33%; */
}
.itemContent .text h2 {
  font-family: var(--bold);
  text-transform: none;
  text-align: left;
  font-size: clamp(1.8rem, 1.3rem + 4vw, 5rem);
}
:root {
  font-size: 10px;
  --bold: "Morion Bold";
  --regular: "Morion Regular";
  --app-height: 100%;
  --radius: 3rem;
  --padding: 5rem;
}

.spojka {
  display: inline-block;
}

.pageStyle .quote {
  padding: 5rem 0;
  font-family: var(--regular);
  text-transform: uppercase;
  max-width: 60%;
  font-size: clamp(3rem, 1.04rem + 4vw, 8rem);
}
body {
  pointer-events: none;
}
body.is-ready {
  pointer-events: all;
}

body.loading main {
  height: 100vh;
  overflow-y: hidden;

  pointer-events: none;
}

body.is-ready main,
body.menu-is-open main {
  height: unset;
  overflow-y: unset;
  pointer-events: all;
}

.introContent.pageFull {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kontakt .introContent {
  justify-content: space-between;
  min-height: 100vh;
  min-height: var(--app-height);
}
.rr {
  width: 50px;
  height: 50px;
  border: 2px solid var(--green);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  z-index: 99999989999 !important;
}
.pp {
  width: 7px;
  z-index: 99999989999 !important;
  height: 7px;
  background-color: var(--green);
  border-radius: 50%;
  margin-bottom: 20rem;
}

img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  object-position: left center;
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}

:root {
  color: #30443e;
  --beige: #e9e8e2;
  --green: #07211f;
  --lightGreen: #d9e1cc;
}

body[data-bg="dark"] {
  background-color: var(--green);
}
body {
  background-color: #e9e8e2;
  font-family: var(--regular);
}
.lightBg {
  background-color: #e9e8e2;
}
.lightGreenBg {
  background-color: var(--lightGreen);
}
.header {
  padding-left: 2rem;
}

.contentNoPad {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0rem;
}

.content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 3rem;
}
.contentFull {
  width: 100vw;
  /* width: 100%;
	max-width: 1600px; */
  margin: 0 auto;
  padding: 3rem;
  margin: 0 auto;
  padding: 3rem;
}
.hide {
  pointer-events: none !important;
}

.soloLink {
  display: block;
  color: var(--green);
  font-family: "PlusJakartaSans-Bold";
}

.non-sticky {
  position: relative;
  top: 0;

  flex-direction: row;
  left: 0;
  z-index: 500;
}

.non-sticky,
.sticky {
  padding: 3rem;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.sticky {
  position: fixed;
  top: 0;

  right: 0;
  z-index: 99999999999;
  transform: translateY(0%);
}

.sticky .buttonHolder {
  width: max-content;
  display: flex;
  flex-direction: row;
}

body.menu-is-open .sticky.hidden {
  transform: translateY(0px);
}

.sticky.hidden {
  transform: translateY(-150px);
}

#goHome {
  pointer-events: all !important;
}
.iframeWrap {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 3rem;
  margin-bottom: 5rem;
  pointer-events: initial;
}

.is-scrolling iframe {
  pointer-events: none;
}
.bookFormInner {
  background-color: white;
  width: 100%;
  padding: 3rem;
  margin: 0 auto;
  padding-top: 65px;
  padding-bottom: 40px;
  border-radius: var(--radius);
}
.help.page h2 {
  padding-bottom: 3rem;
}

.h1 {
  padding-left: 1rem;
  padding-top: 10rem;
  font-family: var(--bold);
  line-height: 0.7;
  padding-bottom: 2rem;
  letter-spacing: -5px;

  font-size: clamp(5rem, 0.0741rem + 14.0741vw, 24rem);
}
.pageDark {
  background-color: var(--green);
}
.pageDark .h1.page,
.pageDark h2 {
  color: var(--lightGreen);
}

.h1.page {
  text-align: center;
  line-height: 1.2;
  letter-spacing: -1px;
  font-size: clamp(3.5rem, 1.6rem + 6vw, 10rem);
  padding: 0 10%;
  line-height: 1.2;
  margin-left: 0 auto;
  position: relative;
}
.h1 img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 80%;
  transform-origin: left;
}

.mainSwitch {
  display: flex;
  flex-direction: row;

  max-height: calc(100vw / 2 * 1.4);
  row-gap: 1rem;
  column-gap: 1rem;
}

.bigSwipe .swiper-slide .button {
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  transform-origin: left bottom;
  z-index: 100;
}
.mainSwitch div {
  position: relative;
  flex: 1;
  border-radius: var(--radius);
  overflow: hidden;
}
.mainSwitch div img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: 50% 50%;
}

h2 {
  text-align: center;
  display: block;
  color: var(--green);
  font-size: 1.5rem;
  font-family: var(--mediumtext);
  text-transform: uppercase;
  font-weight: normal;
}
h2.service {
  text-align: center;
  display: block;
  font-size: 1.5rem;
  font-family: var(--mediumtext);
  text-transform: uppercase;
  font-weight: normal;
  padding: 3rem 0;
}

h2.left {
  text-align: left;
}

.imageSection.maps {
  background-color: var(--green);
}
p {
  font-size: 1.5rem;
  padding-top: 2rem;
  font-family: var(--regulartext);
  font-weight: normal;
  line-height: 1.85;
  line-height: 2;
}
p strong {
  font-family: "PlusJakartaSans-Bold";
}
p span {
  padding-right: 1rem;
  font-family: var(--semiboldtext);
  font-weight: 400;
  font-style: bold;
}
p.center.noWrap {
  text-align: center;
}
p.center {
  text-align: center;
}
p.noWrap {
  white-space: nowrap;
  max-width: 100%;
  margin: 0;
}
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;
  padding: 5rem;
  padding-top: 10rem;
  border-radius: var(--radius) var(--radius) 0 0;
  background-color: var(--lightGreen);
}
.about .button {
  margin: 0 auto;
}
.quote {
  text-align: center;
  font-size: 5rem;
  display: block;
  margin: 0 auto;
  font-weight: normal;
  font-style: normal;
  font-family: var(--bold);
  width: 80%;
  max-width: calc(1600px * 0.8);
  line-height: 1.1;
  padding-top: 15vh;
  padding-bottom: 5rem;
  font-size: clamp(3rem, 1.04rem + 5.6vw, 10rem);
  letter-spacing: -1.5px;
}

.logo {
  max-width: 350px;
  /* max-width: clamp(200px, calc(350px + 0.5rem), 40vw); */
  pointer-events: all;
}

.logo:hover {
  pointer-events: all;
  cursor: pointer;
}

.logotext {
  display: none !important;
}

.logo img {
  max-height: 100px;
}

.medal {
  display: block;
  margin: 0 auto;
  width: 60%;
  max-width: calc(1600px * 0.15);
  margin-top: 20vh;
  margin-bottom: 20vh;
  mix-blend-mode: multiply;
}

.medal img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.restaurace.mapa {
  background-color: var(--lightGreen);
}
.restaurace.lightGreen .inner {
  color: var(--green);
  background-color: var(--lightGreen);
}

.lightGreen {
  background-color: var(--lightGreen);
}
.mapImage {
  background-image: url(../img/mapa.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.restaurace {
  background-color: var(--lightGreen);
  border-radius: 0 0 var(--radius) var(--radius);
  border-radius: var(--radius);
  overflow: hidden;
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}

.swiper-wrapper.noTopBorder {
  border-radius: var(--radius) var(--radius) !important;
  overflow: hidden;
}

.restaurace.fullBorder {
  border-radius: var(--radius);
}
.fullBorder {
  border-radius: var(--radius) !important;
}

.itemBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.itemBox.swiper-wrapper {
  flex-wrap: nowrap;
}

.itemBox.swiper-wrapper .itemBoxItem {
  flex-basis: unset;
  background-color: var(--lightGreen);
  padding: 0rem;
}

.boxSwiper {
  max-width: 100vw;
  overflow: visible;
  overflow-x: hidden;
}

.itemBox .itemBoxItem {
  flex-basis: 33%;
  padding: 2rem;
}

.boxText {
  opacity: 1;
  padding: 3rem;
  border-radius: 0 0 var(--radius) var(--radius);
  background-color: var(--lightGreen);
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}

.boxText h3 {
  font-family: var(--bold);
  text-transform: none;
  text-align: left;
  font-size: clamp(1.6rem, 1.6rem + 3vw, 3rem);
}

.itemBox .itemBoxItem .boxInner {
  display: flex;
  background-color: var(--lightGreen);
  border-radius: var(--radius);
  flex-direction: column-reverse;
}

.itemBox .itemBoxItem .boxImage {
  border-radius: var(--radius);

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  aspect-ratio: 1 / 1.3;
}

/* .itemBox .itemBoxItem:hover .boxImage {
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
} */

.itemBox .itemBoxItem .boxImage img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: 50% 50%;
}
.restaurace .inner {
  display: flex;
  flex: 1;
  min-height: calc(1600px * 0.4);
  max-height: calc(1600px / 2);
}
.restaurace .inner h2 {
  color: var(--lightGreen);
}
.restaurace.mapa {
  border-radius: var(--radius);
  border-radius: 0;
  overflow: hidden;
}
.restaurace.mapa .inner {
  overflow: hidden;
  padding: 2rem;
  /* min-height: calc(1600px * 0.1);
	height: 80vh; */
  border-radius: var(--radius);
  border-radius: 0;
  background-color: var(--lightGreen);
  min-height: calc(1600px * 0.4);
  flex-direction: row;
}
.restaurace.mapa .inner div.img {
  height: 100%;
  min-height: calc(1600px * 0.4);
  border-radius: var(--radius);
  overflow: hidden;
}

.greenLight:first-of-type {
  border-radius: var(--radius) var(--radius) 0 0;
}
.greenLight:last-of-type {
  border-radius: 0 0 var(--radius) var(--radius);
}
.greenLight,
.greenLight .footer {
  border-radius: var(--radius);
  background-color: var(--lightGreen);
  padding-top: 5rem;
}

.restaurace .inner > div {
  flex: 1;
}
.restaurace .inner div.img {
  border-radius: var(--radius);
  overflow: hidden;
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}

.perex {
  margin-top: 3rem;
  display: block;

  line-height: 1.2;
  letter-spacing: -1px;
  font-family: var(--bold);
  font-size: clamp(2.5rem, 1.6rem + 4vw, 8rem);
  padding-bottom: 3rem;
}

.restaurace .inner div.text {
  height: 100%;
  display: block;
  width: 100%;
  display: block;
  text-align: left;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.restaurace .inner div.text.between {
  justify-content: space-between;
}
.restaurace .inner div.text a {
  margin-left: auto;
  margin-top: 2rem;
}

.restaurace .inner div.text > * {
  text-align: left;
}

.restaurace .inner div img {
  object-fit: cover;
}

.restaurace .inner {
  color: var(--lightGreen);
  background-color: var(--green);
}

.lineDivider {
  margin-top: 10rem;
  margin-bottom: 10rem;
  height: 1px;
  width: 100%;
  background-color: var(--green);
}

.button.dark {
  --background: #242836;
  --rectangle: #1c212e;
  --arrow: #f5f9ff;
  --text: #f5f9ff;
  --success: #2f3545;
}
.button.lang {
  --background: rgba(0, 0, 0, 0);
  background: transparent;
  display: none;
}
.button {
  background: #eadfd7;
  display: flex;
  overflow: hidden;
  text-decoration: none;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 50vw;
}
.button.menu {
  background-color: var(--green);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.footerBottom ul {
  padding-bottom: 0.5rem;
}
.footerBottom ul li {
  list-style: none;
  text-align: center;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: right;
  color: var(--green);
  font-family: var(--mediumtext);
  font-size: 1.5rem;
  text-transform: uppercase;
}

.footerBottom ul li a {
  text-decoration: none;
  color: var(--green);
}

.footerBottom ul li a:hover {
  text-decoration: underline;
}
.footerBottom .bottom {
  margin-top: 2rem;
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 2px solid var(--green);
}
.animCharWords .word {
  transform-origin: left;
}
.buttonHolderFooter {
  display: flex;
  flex-direction: row;

  column-gap: 2rem;
}

.footerBottom .bottom .button {
  padding-right: 2rem;
}

.footerBottom .bottom .button {
  background: none;
  border: 2px solid var(--green);
}
.footerBottom .bottom .button div {
  display: none;
}

.light {
  color: var(--lightGreen);
}

.blendNormal {
  mix-blend-mode: unset !important;
}
.button ul {
  margin: 0;
  padding: 10px 1.6rem;
  padding-left: 2.5rem;

  list-style: none;
  text-align: center;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 28px;
  color: var(--green);
  font-family: var(--mediumtext);
  text-transform: uppercase;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  white-space: nowrap;
}
.button.menu {
  background-color: var(--green);
}

.button.menu ul {
  color: var(--lightGreen);
}
.button > div {
  position: relative;
  width: 50px;
  height: 50px;
}
.button > div:before,
.button > div:after {
  content: "";
  display: block;
  position: absolute;
}

.socials {
  display: flex;
  flex-direction: row;
}
.icons.left {
  padding-right: 2.5rem;
  padding-left: 0;
  text-transform: none;
  text-decoration: none;
}
.icons {
  padding: 16px 1.6rem;
  padding-left: 2.5rem;
  padding-right: 0;
  list-style: none;
  text-align: center;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 20px;
  color: var(--green);
  font-family: var(--mediumtext);
  text-transform: uppercase;
}

.button.phone2 {
  margin-right: auto;
}

.button.black {
  transform-origin: center center;
}

.button > div:after {
  width: 60px;
  height: 60px;
  transform-origin: 50% 0;
  border-radius: var(--radius);
  background: var(--beige);

  padding-right: 2rem;
  left: 0;
  top: 1px;
  transform: scaleY(0);
}

.button.menu > div:after {
  background-color: var(--green);
}

.button > div svg {
  background-size: 60%;
  background-image: url(../img/sipka.svg);
  background-repeat: no-repeat;
  background-position: left center;
}
.button.menu > div svg {
  background-size: 60%;
  background-image: url(../img/menu.svg);
  background-repeat: no-repeat;
  background-position: left center;
}
.button.menu.open > div svg {
  background-size: 60%;
  background-image: url(../img/close.svg);
  background-repeat: no-repeat;
  background-position: left center;
}

.button.menu.open {
  background-color: var(--beige);
}

.button.menu.open ul {
  color: var(--green);
}

.imageSection {
  min-height: 120svh;
  overflow: hidden;
  width: 100%;
  background-color: var(--green);
  position: relative;
}
.imageSection.rest,
.imageSection.penz,
.imageSection.map {
  min-height: 100vh;
  min-height: 120vh;
}
.imageSection.rest .inner2,
.imageSection.map .inner2,
.imageSection.penz .inner2 {
  height: calc(120vh);
  min-height: 120vh;
}
.imageSection.rest .inner2 {
  background-image: url(../img/pivo.jpg);
  background-position: center bottom;
}
.imageSection.rest.lesik .inner2 {
  background-image: url(../img/les.jpg);
  background-position: center bottom;
}
.imageSection.penz .inner2 {
  background-image: url(../img/ubytovani.jpg);
  background-position: center bottom;
}
.imageSection.rest .inner,
.imageSection.penz .inner {
  padding-bottom: 5vh;
  justify-content: space-between;
}
.imageSection.map .inner {
  align-items: flex-end;
  justify-content: flex-end;
}
.imageSection.rest .inner h2 {
  color: var(--lightGreen);
  padding-top: 2em;
}
.imageSection.penz .inner h2 {
  color: var(--lightGreen);
  padding-top: 2em;
}

.imageSection.les .inner2 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: 50% 50%;
}
.imageSection .inner2 {
  margin: 0 auto;
  position: absolute;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  /* border-radius: var(--radius); */
  overflow: hidden;

  width: 100vw;
  min-height: 120svh;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageSection .inner2 .swiper-slide {
  min-height: 120svh;
}

.imageSection .inner2 .swiper-slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-height: 120svh;
}
.imageSection .inner {
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  display: block;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageSection .button {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.imageSection.rest .button,
.imageSection.penz .button {
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.topSwipe {
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
  width: 100vw;
  padding-left: 3rem;
  padding-right: 3rem;
}

.par1,
.par2 {
  transform: translate3d(0, 0, 0);
}

.imageSection.les .lesText {
  text-align: center;
}

.lesText {
  padding-top: 3rem;
  max-width: 80%;
  line-height: 1.2;

  letter-spacing: -2px;
  color: var(--lightGreen);
  font-size: clamp(4rem, 1.76rem + 6.4vw, 12rem);
}

.les img {
  object-fit: cover;
}

.inner img.contain {
  object-fit: contain;
}

.swiper-slide .inner img {
  object-fit: cover;
  object-position: 50% 50%;
}
.swiper.fadeSlide {
  border-radius: var(--radius);
  overflow: hidden;
  max-height: calc(100svh - 6rem);
}
.swiper.fadeSlideActivity {
  border-radius: var(--radius) var(--radius);
  overflow: hidden;
}
.swiperHolder:has(.fadeSlide) {
  padding-left: 3rem;
  padding-right: 3rem;
}
.swiper.fadeSlide,
.swiper.fadeSlide .swiperContent,
.swiper.fadeSlide .swiper-slide .inner {
  height: calc(100vw * 0.7);
  max-height: calc(100svh - 6rem);
}

.swiper.fadeSlide.full,
.swiper.fadeSlide.full .swiperContent,
.swiper.fadeSlide.full .swiper-slide .inner {
  height: calc(100vw * 0.7);
  max-height: calc(100svh - 6rem);
}

.swiper.fadeSlide.full .swiper-slide .inner img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.swiperHolder:has(.fadeSlideActivity),
.swiper.fadeSlideActivity,
.swiper.fadeSlideActivity .swiperContent,
.swiper.fadeSlideActivity .swiper-slide .inner {
  height: calc(1600px * 0.7);
}
.swiper.fadeSlideActivity {
  padding: 2rem;
  border-radius: 0 0 var(--radius) var(--radius);
  background-color: var(--green);
  max-height: calc(100svh - 6rem);
}
.swiper.fadeSlideActivity.activity02 {
  background-color: var(--lightGreen) !important;
}
.ubytovaniSwiper:has(.fadeSlide) {
  padding-left: 3rem;
  padding-right: 3rem;
}

.ubytovaniSwiper {
  padding-top: 10rem;
  padding-bottom: 5rem;
  max-width: 100vw;
  overflow: hidden;
}

.smallSwipe .swiperContent {
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  padding: 0 1.5rem 0 1.5rem;
  padding-left: 3rem;
  padding-right: 0;
}
.smallSwipe .swiperContent .inner img {
  transform: translate3d(0, 0, 0);
}
.footer {
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  background-color: var(--beige);
}

.footer.smallFoot {
  min-height: unset;
}
.topFooter {
  margin-top: auto;
  /* padding-bottom: 20rem;
	padding-top: 10rem; */
}
.footerLogo {
  display: block;

  position: relative;
  margin: 0 auto;
  transform-origin: center center;
}
.footerLogo,
.footerLogo svg {
  width: calc(1600px * 0.08);
  height: calc(1600px * 0.08);
}
.footer .footerLogo,
.footer .footerLogo svg {
  width: calc(1600px * 0.11);
  height: calc(1600px * 0.11);
}
.animals,
.animals2 {
  transform-origin: bottom center;
}
.footerBottom {
  max-width: 1600px;
  width: 100vw;
  position: relative;
  margin: 0 auto;
  padding: 5rem;
  margin-top: auto;
}

#logoPreloader .animals {
  transform: translateY(100%);
  opacity: 0;
}

.footerBottom .top {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
}

.footerBottom .top div {
  display: flex;
  flex-direction: column;
}
.footerBottom .top div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerBottom .top div:nth-of-type(1) .perex {
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}
.footerBottom .perex {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.footerBottom .top div:nth-last-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.swiper-progress-bar {
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;
  height: 2px;
}
.swiper-progress-bar .slide_progress-bar {
  position: absolute;
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  width: auto;
  clear: both;
  opacity: 0;
  bottom: 45px;
  left: 0;
  right: 0;
}
.swiper-progress-bar .slide_progress-bar:after {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 0;
  content: "";
  transition: 0.1s width linear;
}
.swiper-progress-bar.active .slide_progress-bar {
  opacity: 1;
}
.swiper-progress-bar.animate .slide_progress-bar:after {
  transition: width linear;
  transition-delay: unset;
  width: 100%;
  transition-duration: 5s;
}

.swiper-hero-progress {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 0;
  height: 3px;
  background: #fff;
  z-index: 5;
}

#logoPreloader {
  opacity: 0;
  transform: scale(0.5);
}
.introContent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smallSwipe .swiper-slide {
  width: var(--item);
}
.bigSwipe .swiper-slide {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  max-width: calc(1600px / 2);

  position: relative;
}
.bigSwipe .swiper-slide .swiperContent {
  border-radius: var(--radius);
  overflow: hidden;
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}
.bigSwipe .swiper-wrapper {
  column-gap: 1rem;
}
.smallSwipe .swiper-slide:nth-of-type(1) .swiperContent {
  padding-left: 2rem;
}
.singleSwipe .swiper-slide:nth-of-type(1) .swiperContent {
  padding-left: 2rem;
  padding-right: 2rem;
}

.bigSwipe .swiper-slide .inner {
  height: 100%;
}
.smallSwipe .swiper-slide .inner {
  overflow: hidden;
  height: calc(var(--item) * 1.3);
  max-height: 80vh;
  border-radius: var(--radius);
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}
.smallSwipe.singleSwipe .swiper-slide .inner {
  overflow: hidden;
  max-height: unset;
  height: calc(100vw * 0.7);
  max-height: calc(1600px * 0.7);
  border-radius: var(--radius);
  transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
}
.ubytovani {
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.ubytovani.evenCol {
  align-items: flex-start;

  gap: 5rem;
}
.ubytovani.evenCol.par {
  align-items: flex-start;
  flex: 1;
  gap: 5rem;
}
.ubytovani.evenCol div .perex {
  margin-top: 0;
}
.ubytovani.evenCol > div > p {
  max-width: 80%;
  margin-left: 15%;
}
.ubytovani.evenCol.par > div > p {
  max-width: 80%;
  margin-left: 0%;
}
.ubytovani.evenCol > div:first-child {
  white-space: nowrap;
}
.ubytovani.evenCol.par > div:first-child {
  white-space: initial;
}

.ubytovani .perex {
  padding-bottom: 0;
  word-wrap: none;
}
.lineDividerWrap {
  padding: 0 5rem 0 5rem;
}

.paddedContent {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.darkBg {
  background-color: var(--green);
}

.lightGreen {
  background-color: var(--lightGreen);
}

.darkBg p,
.darkBg .quote,
.darkBg .perex,
.darkBg p a,
.darkBg h2 {
  color: var(--lightGreen);
}

.nav-wrap {
  width: 100%;
  margin: 0;
}

.faqAC > div.ac {
  background-color: var(--green) !important;
  color: var(--lightGreen) !important;
  border: 0;
}

.faqAC > div .ac-trigger {
  padding: 0;
  font-family: var(--bold);
  font-size: 2.5em;
  font-style: normal;
  font-weight: normal;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.ubytovani.evenCol.par .faqAC {
  max-width: 100%;
  margin-left: 0;
}
.faqAC {
  max-width: 80%;
  margin-left: 15%;
  counter-reset: section;
}

.faqAC > div .ac-trigger::before {
  width: 5rem;
  content: "0" counter(section);
  counter-increment: section;
  font-family: var(--regulartext);
  font-size: 0.5em;
  transform: translateY(-0.5rem);
  display: inline-block;
}
.faqAC .ac-trigger {
  padding-left: 1rem;
  color: var(--lightGreen) !important;
}
.faqAC .ac.is-active p {
  opacity: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 200ms;
}
.faqAC p {
  opacity: 0;
  font-family: var(--regulartext);
  color: var(--lightGreen) !important;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 200ms;
  padding-left: 6rem;
  padding-bottom: 5rem;
}
.faqAC .ac::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: relative;
  background-color: var(--lightGreen);
  transform: unset;
  top: unset;
  right: unset;
  opacity: 0.5;

  bottom: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.faqAC .ac.is-active::after,
.faqAC .ac.is-active:hover::after {
  opacity: 0.5;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
.faqAC .ac.is-active .ac-trigger::after {
  content: "-";
}

.columns2 {
  padding-top: 5rem;
  column-count: 3;
  column-gap: 10rem;
  padding-left: 5rem;
  padding-right: 5rem;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.columns2Flex {
  padding-top: 5rem;
  column-count: 3;
  flex: 1;
  column-gap: 10rem;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: row;
  gap: 10rem;
  padding-bottom: 5rem;
}

.columns2Flex > div {
  flex: 1;
}
.columns2 h2:first-of-type {
  margin-top: 0;
}
.columns2 h2,
.columns2Flex h2 {
  font-family: "PlusJakartaSans-ExtraBold";
  text-align: left;
  padding-bottom: 1em;
  margin-top: 2em;
  font-size: 1.8em;
  font-weight: bold;
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
.columns2 p {
  padding-top: 0;
  margin-bottom: 2rem;
}
.listek p {
  display: flex;
  padding-bottom: 1rem;
  flex-direction: row;
  width: 100%;
  display: block;
  justify-content: space-between;
}
.listek.oneLine p {
  justify-content: flex-start;
  font-size: 1.5rem !important;
}
.ubytovani.fullW {
  width: 100%;
  align-items: stretch !important;
  display: block;
}
.listek.oneLine p span {
  font-size: 1.5rem !important;
  width: max-content;
}
.listek p:last-of-type {
  padding-bottom: 5rem;
}

.listek p span:first-child {
  width: 12rem;
}
.listek.oneLine p span:first-child {
  width: max-content !important;
}
.listek.oneLine p span,
.listek p span:nth-of-type(2) {
  font-family: var(--mediumtext);
  margin-right: auto;
}
.listek p span:last-child {
  padding-left: 1rem;
}
.listek p span:first-child,
.listek p span:last-child {
  font-size: 0.8em;
  transform: translateY(0.5rem);
}
.menu-is-open .menuHolder {
  transform: translateY(0%);
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}
.curtains {
  width: 100vw;
  height: 100svh;
  background-color: var(--green);
  position: fixed;
  top: 0;
  display: block;

  width: 100vw;
  min-height: calc(100vh + var(--radius));
  /* border-radius: var(--radius) var(--radius) 0 0; */
  background-color: var(--green);
  min-height: 100vh;
  top: 100vh;
  z-index: 999999999999999999999;
}
.dummy {
  width: 100vw;
  height: 100vh;
  background-color: var(--lightGreen);
  position: fixed;

  display: block;

  width: 100vw;

  height: 100svh;
  /* border-radius: 0 0 var(--radius) var(--radius); */
  background-color: var(--green);

  top: 0px;
  z-index: 999999999999999999999;
}

.menuHolder::after {
  display: none;
  content: "";
  width: 100vw;
  min-height: calc(100vh + var(--radius));
  border-radius: var(--radius) var(--radius) 0 0;
  background-color: var(--green);
  position: absolute;
  top: 0;
  z-index: 999999999999999999999;
  transform: translateY(100%);
}

.is-hiding .buttonHolder:has(a.button.menu) {
  transform: translateY(-200%) !important;
}
/* .buttonHolder a.button.menu::after {
	display: block;
	position: absolute;
	top: -1px;
	left: -1px;
	content: "";
	height: calc(100% + 2px);
	width: calc(100% + 2px);
	z-index: -1;
	padding: 2px;
	border-radius: 50vw;
	background-color: #e9e8e2;
	opacity: 0.3;
	mix-blend-mode: exclusion;
} */
.menuHolder {
  position: fixed;
  top: 0;
  width: 100vw;
  filter: brightness(0.9);
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--beige);
  z-index: 9999999;
  transform: translateY(-100%);
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}

.menuHolder .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;

  padding-left: 5rem;
  padding-bottom: 5rem;

  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  min-height: 100svh;

  /* min-height: var(--app-height);
   */
  height: 100svh;
}
.buttonHolder {
  z-index: 99999999;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
.menuItems h3 {
  text-align: center;
  text-align: left;
  display: block;
  font-size: 1.5rem;
  font-family: var(--mediumtext);
  text-transform: uppercase;
  font-weight: normal;
}
.menuItems div h3 {
  margin-bottom: 5rem;
  opacity: 0;
  /* transform: translateY(-100%); */
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}
.menu-is-open .menuItems div h3 {
  margin-bottom: 5rem;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);

  transform: translateY(0%);
  opacity: 1;
}

.menu-is-open .menuItems a {
  transform: translateY(0%);
  opacity: 1;

  -webkit-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
}
.menu-is-open .menuItems a:hover {
  opacity: 0.5;
  transition-delay: 0s !important;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
/* }
.menu-is-open .menuItems a:nth-of-type(1) {
	transition-delay: 0.2s;
}
.menu-is-open .menuItems a:nth-of-type(2) {
	transition-delay: 0.3s;
}
.menu-is-open .menuItems a:nth-of-type(3) {
	transition-delay: 0.4s;
}
.menu-is-open .menuItems a:nth-of-type(4) {
	transition-delay: 0.5s;
}
.menu-is-open .menuItems a:hover:nth-of-type(1) {
	transition-delay: 0s !important;
}
.menu-is-open .menuItems a:hover:nth-of-type(2) {
	transition-delay: 0s !important;
}
.menu-is-open .menuItems a:hover:nth-of-type(3) {
	transition-delay: 0s !important;
}
.menu-is-open .menuItems a:hover:nth-of-type(4) {
	transition-delay: 0s !important;
} */
.menuItems a {
  padding: 0rem 0;
  display: block;
  opacity: 0;
  text-align: left;
  line-height: 1.2;
  letter-spacing: -1px;
  font-size: clamp(3.5rem, 1.6rem + 4vw, 10rem);
  font-family: var(--bold);
  line-height: 1.2;

  position: relative;
  color: var(--green);
  color: #30443e;
  text-decoration: none;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

body.menu-is-open main {
  pointer-events: none;
}
body.menu-is-open .button.menu {
  pointer-events: all;
}
.menuItems a:last-of-type {
  padding-bottom: 5rem;
}
.buttonHolder:has(a.button.menu) {
  opacity: 0;
  transform: scale(0.8);
}
.buttonHolder:has(a.button.menu.show) {
  opacity: 1;
  transform: scale(1);
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.buttonHolder:has(a.button) {
  pointer-events: all;
}
.menu-is-open .menuHolder .ubytovani.evenCol.par p {
  opacity: 1;
  transform: translateY(0%);
  -webkit-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.menuHolder .ubytovani.evenCol.par {
  padding-left: 0;
  width: 100%;
  padding-top: 3rem;
}

.menuHolder .ubytovani.evenCol.par p {
  font-size: 1.5rem;
  padding-top: 0;
  opacity: 0;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translateY(-100%);
}

.price-tag {
  background-color: #4285f4;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285f4;
}

[class$="api-load-alpha-banner"] {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  top: auto;
  bottom: unset;
  width: 6rem;
  height: 6rem;
  top: unset;
  left: unset;
  right: unset;

  background-color: #eadfd7;
  border-radius: 50vw;
  background-image: url(../img/sipka.svg);
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -moz-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  -o-transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  position: relative;
}

#swiperButtonHeight {
  opacity: 0;
}
.swiper-button-Holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
  height: 10rem;
  margin-top: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
.swiper.fadeSlideActivity .swiper-button-Holder {
  transform: translateY(-15rem);
  z-index: 500;
  width: 100%;
}
.swiper-button-Holder.center {
  justify-content: center;
}

.iframeWrap iframe {
  overflow-y: hidden;
}

.swiper-pagination-progressbar {
  margin: 0 auto;
  background: #fff9;
  margin-top: 5rem;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}
.swiper-button-prev {
  transform: rotate(180deg);
  margin-left: 3rem;
  margin-right: 1rem;
}
.swiper-button-next {
  margin-left: 1rem;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
