:root {
	font-size: 10px;
	--bold: "Morion Bold";
	--regular: "Morion Regular";
	--mediumtext: "PlusJakartaSans-Medium";
	--regulartext: "PlusJakartaSans-Regular";
	--semiboldtext: "PlusJakartaSans-Semibold";
	--boldtext: "PlusJakartaSans-Bold";
	--radius: 5rem;
	--padding: 5rem;
}

html {
	scroll-behavior: initial;
}

.noise {
	z-index: 9999999998;
	position: fixed;
	mix-blend-mode: darken;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	opacity: 0.04;
}

.preloader {
	/* display: none !important; */
	z-index: 999999999999;
	position: fixed;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh + var(--radius));
	pointer-events: none;

	background-color: #07211f;

	align-items: center;
	border-radius: 0 0 var(--radius) var(--radius);
}
.preloader .preWrap {
	max-height: 100vh;
	height: var(--app-height);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.preloader .topFooter {
	margin: unset;
}

.preloadIcon {
	opacity: 0;
	width: 9vw;
	height: 9vw;
}

.preloadIcon img {
	object-fit: contain;
	height: 100%;
	width: 100%;
}

.effects {
	pointer-events: none;
	height: 100%;
	width: 100vw;
}

@-webkit-keyframes spinLoader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spinLoader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
